// eslint-disable-next-line import/no-extraneous-dependencies
import { Route, Routes } from "react-router-dom";
import App from "./App";
// import DocViewer from "./doc-viewer";
// import UpdatedDocViewer from "./new-doc-viewer";

export const Router = () => (
  <Routes>
    <Route path="/" element={<App />} />
    {/* <Route
			path="/privacy-policy"
			element={<DocViewer src="pdf/privacy-policy.html" />}
		/>
		<Route
			path="/terms-of-use"
			element={<DocViewer src="pdf/terms-of-use.html" />}
		/>
		<Route
			path="/subscription-terms"
			element={<UpdatedDocViewer src="pdf/subscription-terms.html" />}
		/> */}
  </Routes>
);
