// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from "react-router-dom";

function App() {
  const handleDownloadClick = () => {
    const url = "";
    window.open(url, "_top", "noopener,noreferrer");
  };

  return (
    <div className="wrapper">
      <header className="header">
        <img src="/images/logo.png" alt="background" className="blushed-logo" />
        <img
          src="/images/get-app.png"
          onClick={handleDownloadClick}
          className="button-get-app hover"
          alt="Get App"
        />
      </header>
      <div className="main-wrapper">
        <img
          src="/images/background.png"
          alt="background"
          className="blushed-bg"
        />
        {/* <div className="button-top">
          
        </div> */}
        <div className="download-block">
          <img
            src="/images/play-store.png"
            className="button-download hover"
            alt="Google Play"
            onClick={handleDownloadClick}
          />
          <img
            // onClick={handleDownloadClick}
            className="button-download"
            src="/images/app-store.png"
            alt="App Store"
          />
        </div>
      </div>
      {/* <img src="/images/footer.png" alt="footer" className="blushed-bg" /> */}
      <div className="policy-wrapper">
        {/* <div className="line" /> */}
        <div className="logo-wrapper">
          <img
            src="/images/logo.png"
            alt="background"
            className="blushed-logo"
          />
          <p className="policy-item">All Rights Reserved © 2024</p>
        </div>
        <div className="link-wrapper">
          <Link to="/terms-of-use" style={{ textDecoration: "none" }}>
            <div className="policy-item">Terms</div>
          </Link>
          <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
            <div className="policy-item">Privacy</div>
          </Link>
          <Link to="/subscription-terms" style={{ textDecoration: "none" }}>
            <div className="policy-item">Subscription terms</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
